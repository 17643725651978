import axios from './instance'

export const assignRouteToDriver = (data) => {
  return axios.post('/assign/route', data)
}

export const updateAssignedRoute = (id, data) => {
  return axios.put(`/assign/route/${id}`, data)
}

export const getAssignedRouteById = (id) => {
  return axios.get(`/assigned/route/${id}`)
}

export const deleteAssignedRoute = (id) => {
  return axios.delete(`/assign/route/${id}`)
}

export const getAssignedRoutesByRouteId = (id, data) => {
  return axios.get(`/assigned/routes/route/${id}?${data}`)
}

export const getAssignedRoutesByCustomerId = (id, data) => {
  return axios.get(`/assigned/routes/customer/${id}?${data}`)
}

export const getAssignedRoutesByDriverId = (id, data) => {
  return axios.get(`/assigned/routes/driver/${id}?${data}`)
}

export const getAvailableCustomersToAddOnAssignedRoute = (id) => {
  return axios.get(`/assign/route/${id}/assignable-customers`)
}

export const assignCustomersToRoute = (id, data) => {
  return axios.post(`/assign/route/${id}/add-customers`, data)
}

export const removeCustomerFromAssignedRoute = (id, customerId) => {
  return axios.delete(`/assign/route/${id}/remove-customer/${customerId}`)
}

export const getCustomerAddableBins = (id, customerId) => {
  return axios.get(`/assign/route/${id}/addable-customer-bins/${customerId}`)
}

export const assignedRouteRemoveCustomerBin = (id, customerId, binId) => {
  return axios.delete(`/assign/route/${id}/customer/${customerId}/remove-bin/${binId}`)
}

export const assignedRouteAddCustomerBins = (id, customerId, data) => {
  return axios.post(`/assign/route/${id}/add-customer-bins/${customerId}`, data)
}

export const updateCustomerRemarks = (id, customerId, data) => {
  return axios.put(`/assign/route/${id}/update-remarks/${customerId}`, data)
}

export const updateSequenceOrder = (id, data) => {
  return axios.put(`/assign/route/${id}/update-order`, data)
}

export const generateReport = (id) => {
  return axios.get(`/assigned/route/${id}/generate`, {
    responseType: 'blob'
  })
}

export default {
  assignRouteToDriver,
  updateAssignedRoute,
  deleteAssignedRoute,
  getAssignedRouteById,
  getAssignedRoutesByRouteId,
  getAssignedRoutesByCustomerId,
  getAssignedRoutesByDriverId,
  getAvailableCustomersToAddOnAssignedRoute,
  assignCustomersToRoute,
  removeCustomerFromAssignedRoute,
  getCustomerAddableBins,
  assignedRouteRemoveCustomerBin,
  assignedRouteAddCustomerBins,
  updateCustomerRemarks,
  updateSequenceOrder,
  generateReport
}
