import assignRoute from '@/api/assign_route'

export const assignRouteToDriver = (data) => {
  return assignRoute.assignRouteToDriver(data)
}

export const updateAssignedRoute = (id, data) => {
  return assignRoute.updateAssignedRoute(id, data)
}

export const getAssignedRouteById = (id) => {
  return assignRoute.getAssignedRouteById(id)
}

export const deleteAssignedRoute = (id) => {
  return assignRoute.deleteAssignedRoute(id)
}

export const getAssignedRoutesByRouteId = (id, data) => {
  return assignRoute.getAssignedRoutesByRouteId(id, data)
}

export const getAssignedRoutesByCustomerId = (id, data) => {
  return assignRoute.getAssignedRoutesByCustomerId(id, data)
}

export const getAssignedRoutesByDriverId = (id, data) => {
  return assignRoute.getAssignedRoutesByDriverId(id, data)
}

export const getAvailableCustomersToAddOnAssignedRoute = (id) => {
  return assignRoute.getAvailableCustomersToAddOnAssignedRoute(id)
}

export const assignCustomersToRoute = (id, data) => {
  return assignRoute.assignCustomersToRoute(id, data)
}

export const removeCustomerFromAssignedRoute = (id, customerId) => {
  return assignRoute.removeCustomerFromAssignedRoute(id, customerId)
}

export const getCustomerAddableBins = (id, customerId) => {
  return assignRoute.getCustomerAddableBins(id, customerId)
}

export const assignedRouteRemoveCustomerBin = (id, customerId, binId) => {
  return assignRoute.assignedRouteRemoveCustomerBin(id, customerId, binId)
}

export const assignedRouteAddCustomerBins = (id, customerId, data) => {
  return assignRoute.assignedRouteAddCustomerBins(id, customerId, data)
}

export const updateCustomerRemarks = (id, customerId, data) => {
  return assignRoute.updateCustomerRemarks(id, customerId, data)
}

export const updateSequenceOrder = (id, data) => {
  return assignRoute.updateSequenceOrder(id, data)
}

export const generateReport = (id) => {
  return assignRoute.generateReport(id)
}

export default {
  assignRouteToDriver,
  updateAssignedRoute,
  getAssignedRouteById,
  deleteAssignedRoute,
  getAssignedRoutesByRouteId,
  getAssignedRoutesByCustomerId,
  getAssignedRoutesByDriverId,
  getAvailableCustomersToAddOnAssignedRoute,
  assignCustomersToRoute,
  removeCustomerFromAssignedRoute,
  getCustomerAddableBins,
  assignedRouteRemoveCustomerBin,
  assignedRouteAddCustomerBins,
  updateCustomerRemarks,
  generateReport,
  updateSequenceOrder
}
