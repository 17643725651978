<template>
  <div class="modal-card custom" style="width: auto">
    <form @submit.prevent="add">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Customers</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" :class="isDropdownOpen ? 'min-height-container' : null">
        <b-progress v-if="isLoading" size="is-large" show-value>
          Fetching Customers List
        </b-progress>
        <div v-else>
          <label class="typo__label">Customers List</label>
          <multiselect
            v-model="customers"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Customers"
            label="name"
            track-by="id"
            openDirection="bottom"
            tagPosition="bottom"
            @open="onOpen"
            @close="onClose"
            >

            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} customers selected</span>
            </template>

          </multiselect>
        </div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!isSelectedChanged"
          >Add Customers</b-button
        >
      </footer>
    </form>
    </div>
</template>

<script>
import route from '@/services/route'
import assignRoute from '@/services/assign_route'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AddRouteCustomers',
  components: {
    Multiselect
  },
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    },
    routeId: {
      type: Number,
      default: 0
    },
    customersData: {
      type: Array,
      default: () => []
    }
  },
  async mounted () {
    this.isLoading = true
    await this.loadAddableCustomers()
    this.isLoading = false
  },
  data () {
    return {
      isLoading: false,
      isDropdownOpen: false,
      customers: JSON.parse(JSON.stringify(this.customersData)),
      options: []
    }
  },
  methods: {
    add () {
      if (this.isSelectedChanged) {
        this.$emit('onCustomersAdd', {
          customers: this.customers,
          close: () => { this.$emit('close') }
        })
      }
    },
    async loadAddableCustomers () {
      try {
        if (this.isAssigned) {
          this.options = await assignRoute.getAvailableCustomersToAddOnAssignedRoute(this.routeId)
        } else {
          this.options = await route.getAddableCustomersOnRoute(this.routeId)
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$emit('close')
      }
    },
    onOpen () {
      this.isDropdownOpen = true
    },
    onClose () {
      this.isDropdownOpen = false
    }
  },
  computed: {
    isSelectedChanged () {
      return JSON.stringify(this.customersData) !== JSON.stringify(this.customers)
    }
  }
}
</script>

<style scoped>
  .min-height-container {
    min-height: 450px;
  }
</style>
