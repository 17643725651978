import route from '@/api/route'

export const createRoute = (data) => {
  return route.createRoute(data)
}

export const getRoutes = (query) => {
  return route.getRoutes(query)
}

export const deleteRouteById = (id) => {
  return route.deleteRouteById(id)
}

export const getRouteById = (id) => {
  return route.getRouteById(id)
}

export const updateRouteById = (id, data) => {
  return route.updateRouteById(id, data)
}

export const getAddableCustomersOnRoute = (id) => {
  return route.getAddableCustomersOnRoute(id)
}

export const updateCustomersToRoute = (id, data) => {
  return route.updateCustomersToRoute(id, data)
}

export const removeCustomerToRoute = (id, customerId) => {
  return route.removeCustomerToRoute(id, customerId)
}

export default {
  getRoutes,
  deleteRouteById,
  createRoute,
  getRouteById,
  updateRouteById,
  getAddableCustomersOnRoute,
  updateCustomersToRoute,
  removeCustomerToRoute
}
